body, .ant-layout-content{
    background-color: #fff !important;
}
.ant-menu-item-only-child{
    padding-left: 18px !important;
}
.body-root{
    font-family: Muoto,-apple-system,BlinkMacSystemFont,Segoe UI,sans-serif !important;
    background-color: #fff;
}
.ant-layout-content, .ant-menu-title-content{
    font-family: 'Nunito', sans-serif !important;
}
.anticon-menu-fold{
    margin: 12px;
}
.banner-title{
    font-size:4em;
    padding:50px; 
    padding-top:20px; 
    padding-bottom:0px;
}
.banner-text-welcom{
    font-size:25px;padding:0px 50px;
}
.home-section-welcom{
    background-image: url(assets/bgcfao.jpg);
    background-color: #1b1f2b !important;
    background-size: cover;
    background-position: right;
    padding: 50px 0px 50px 50px;
    background-repeat: no-repeat;
    text-align: center;
    position: relative;
    z-index: 1;
}
.home-section-welcom::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255 207 92 / 15%);
    z-index: -1;
}
.btn-full-width{
    width: 100%;
}
.main-icon-text-banner{
    color: #ffffff !important;
}
.main-icon-banner{
    font-size: 3rem;
    color: #ffffffb8 !important;
}
.card-banner{
    background-color: #ffffff57 !important;
    border-radius: 15px !important;
}
.bg-info-transparent{
    margin-right: 10px;
    background-color: #25cff236;
    color: #033540;
    font-weight: 600;
    border-color: #25cff236;
}
.group-item-cust:hover{
    background: #ffd43b !important;
    cursor: pointer;
    transition: all 400ms ease;
}
.group-item-cust{
    padding: 5px 16px 5px 5px;
    font-size: 15px;
}
.float-right{
    float: right;
}
.badge-warning {
    color: #212529;
    background-color: #ffc107;
}
.header-text-cust{
    font-weight: 500;
    vertical-align: top;
    font-size: 18px;
}
.list-icon{
    color: #339af0;
    font-size: 25px;
    margin-right: 15px;
}
.ant-menu-title-content {
    flex: none !important;
}
a{
    text-decoration: none !important;
}
.ant-upload-wrapper .ant-upload {
    width: 100%;
}
.ant-btn-icon-only{
    padding-bottom: 26px !important;
}
.ant-btn-icon-only span{
    vertical-align: middle !important;
}
.ant-layout-header {
    padding-inline: 0px !important;
}
.btn-banner{
    background: #ffffff5c;
    border-color: #fff0;
    color: #FFE70F;
}
.bglight{
    background-color: #f8f9fa;
}
.btn-primary {
    background: #003a70 !important;
    border-radius: 4px;
    font-weight: bold;
    border-color: #003a70 !important;
}
.btn-primary:hover, .btn-primary:active {
    background: #003a70d6 !important;
    border-color: #003a70d6 !important;
}
.nav-link {
    font-weight: 500;
    color:#000;
    cursor: pointer;
    font-size:"18px";
}
table{
    font-size: 14px;
}
table th{
    font-size: 16px;
}
.fc-event {
    border-radius: 10px;
    border: none;
}
.fc-scroller {
    background-color: #f2f2f2;
    /* Add any other styling properties as needed */
}
th .fc-col-header-cell{
    background-color: #ffcf5c;
    border-radius: 8px 8px 0px 0px;
}
th .fc-scrollgrid-sync-inner a{
    color: #fff !important;
}
th .fc-scrollgrid-sync-inner{
    padding: 8px;
}
/* .manager-space .ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: #ffcf5c;
}
.manager-space .ant-menu-dark .ant-menu-item-selected {
    background-color: #ffcf5c;
}
.manager-space .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
    background-color: #ffcf5c;
}
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: #ffcf5c !important;
}
.manager-space .ant-menu-submenu-title {
    background-color: #b5840d !important;
    margin: 0 !important;
    border-radius: 0px !important;
    width: 100% !important;
}
.manager-space .ant-menu-item-group-title{
    background-color: #ffcf5c;
} */
/* .manager-space .ant-menu-item{
    margin-inline: 0px !important;
    margin-block: 0px !important;
    width: 100% !important;
    border-radius: 0px !important;
    background-color: #dba72a !important;
} */
.ant-table-wrapper .ant-table-thead>tr>th{
    padding: 8px 8px;
}
.ant-table-wrapper .ant-table-tbody>tr>td{
    padding: 8px 8px;
}
.custom-spinner{
    width: 20px;
    height: 20px;
    color: #ffe70f;
}
.custom-checkbox {
    width: 20px; /* set the width */
    height: 20px; /* set the height */
    display: block;
}
.form-label {
    font-size: 16px;
}

circle {
    fill: steelblue;
  }
  
  .line-path {
    fill: maroon;
  }
  
  text {
    font-family: sans-serif;
  }
  
  .tick text {
    font-size: 15px;
    fill: #635F5D;
  }
  
  .tick line {
    stroke: #C0C0BB;
    display: none;
  }
  
  .axis-label {
    font-size: 3em;
    fill: #8E8883;
  }
  
  .title {
    font-size: 2em;
    fill: #635F5D;
  }

.report-title {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}
.pie-chart-label {
    font-size: 15px;
    font-weight: 600;
    fill: white; /* Couleur du texte */
}
.pie-chart-legend{
    font-size: 15px;
    font-weight: 600;
}
.arc text {
    pointer-events: none;
}
.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    font-size: 12px;
    border-radius: 3px;
    z-index: 999;
}
.ant-select-disabled .ant-select-selector{
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #e9ecef !important;
    color: #212529 !important;
    border: 1px solid #ced4da !important;
    cursor: not-allowed !important;
}
.table-row-light {
    background-color: #f5f7fb;
}
.table-row-dark {
    background-color: #fbfbfb;
}
.ant-table-thead tr{
    background-color: #f5f7fb !important;
}

.ant-table-thead >tr>th{
    background: #e2e8f3 !important;
}
.form-input-border-red{
    border-color: rgba(194, 4, 4, 0.25);;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(194, 4, 4, 0.25);
}
.bg-default{
    background-color: #ffcf5c !important;
}
.ant-select-selection-placeholder {

    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
}
.item_card {
    transition: box-shadow 0.3s ease;
}
.item_card:hover{
    box-shadow: rgb(255 207 92) 0px 2px 5px 0px, rgb(255 207 92) 0px 1px 1px 0px !important;
}
.resolution-rate-widget {
    text-align: center;
}

.resolution-rate-widget h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
}

.resolution-rate-value {
    font-size: 7rem;
    font-weight: 200;
    color: #007bff; /* You can change the color as needed */
}
.resolution-rate-value .rate{
    font-size: 2rem;
}

.no-data-message {
    font-style: italic;
    color: #6c757d;
}
.ant-select .ant-select-arrow{
    color:#0d2457;
}
.ant-select-dropdown{
    z-index: 999999;
}

element.style {
    width: 40%;
    font-weight: 600;
}
.ant-btn-default:hover {
    color: #ffcf5c !important;
    border-color: #ffcf5c !important;
    background: #ffffff;
}
.ant-picker:hover{
    border-color: #ffcf5c !important;
    background-color: #ffffff;
}
.ant-select:hover.ant-select-selector{
    border-color: #ffcf5c !important;
    background-color: #ffffff;
}
.ant-select-selector:hover{
    border-color: #ffcf5c !important;
    background-color: #ffffff;
}
.collapsed-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease; /* Définit une transition "ease" */
}
.bg-secondary {
    background-color: rgb(226, 232, 243) !important;
    color: #000;
}
/* .manager-space .ant-menu-item {
    background-color: #b5840d !important;
} */
.ant-tabs-nav{
    margin-bottom: 0px !important;
}
.ant-tabs-tab-btn{
    color:"#342e2e";
    font-size: 18px;
    font-weight: 600;
}
.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #342e2e !important;
}
.ant-tabs .ant-tabs-ink-bar {
    position: absolute;
    background: #342e2e;
    pointer-events: none;
    height: 4px;
}
.ant-tabs .ant-tabs-tab:hover {
    color: #342e2e !important;
}
.ant-tabs{
    color: #342e2e !important;
}
#menu-apps .ant-menu-item {
    padding: 0px !important;
    background-color: yellow !important;
    color: #000;
}

#menu-apps .ant-menu-title-content {
    padding: 15px !important;
    padding-bottom: 15px !important;
    margin-top: 15px;
}

#menu-apps .ant-menu-item .material-symbols-outlined{
    font-size: 40px;
}
.manager-space .ant-menu-submenu-title {
    padding-left: 10px !important;
    padding-right: 0px !important;
}
.ant-menu-inline .ant-menu-item {
    height: 30px;
}
.ant-menu-dark .ant-menu-item-selected{
    background-color: #f5f7fb70 !important;
}
.ant-segmented .ant-segmented-item-selected{
    background-color: #ffcf5c;
}
.ant-menu-item-group-title{
    font-size: 12px !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #ffcf5c !important;
    border-color: #ffcf5c !important;
    color:"#000" !important;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #ffcf5c !important;
    border-color: #ffcf5c !important;
}
.ant-steps-item-title::after {
    background-color: #001529 !important;
}
.ant-steps .ant-steps-item-icon .ant-steps-icon {
    color: #001529 !important;
}
.bg-info {
    color: #fff;
    background-color: rgb(93 104 121) !important;
}
.list-card:hover .button-container {
    display: block !important;
}

.btn-cool {
    color: #fff;
    background-color: rgb(93 104 121) !important;
}
.row-kanban {
    flex-wrap: nowrap !important;
}
.primary{
    background-color: #ffcf5c !important;
    border-color: #ffcf5c !important;
    color: #000;
}

.nav-item .active span{
    color: #ffcf5c !important;
}
.nav-item .active i{
    color: #ffcf5c !important;
}
.ticket-item {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .ticket-info p {
    margin: 0;
    color: #555;
  }
  
  .ticket-status {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ticket-users {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    font-weight: bold;
  }
.ant-table-thead >tr>th {
    background: #ffffff !important;
}
.item-container {
    margin-top: 0px;
    min-height: 130px;
    padding: 10px;
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    margin-bottom: 0px;
    background-color: #f9f9f9;
    box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px;
}
.item-container-bis{
    padding: 10px 0px;
    border: 0px solid #ddd;
    border-radius: 8px;
    margin-top: 0px;
    height: 45px;
    max-height: 45px;
    min-height: 45px;
    margin-bottom: 0px;
    /* background-color: #f9f9f9; */
    /* box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px; */
}

.item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
}

.item-number {
    font-weight: bold;
    font-size: 14px;
    color: #333;
}

.item-status {
    color: green;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.item-name {
    margin: 0;
    font-size: 14px;
    color: #666;
}

/* Flexbox for item info */
.item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 10px; */
}

/* Style for item status */
.item-status {
    color: green;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

/* Style for department column */
.department-column {
    font-size: 14px;
    color: #333;
    padding: 10px;
}

/* Style for date column */
.date-column {
    font-size: 14px;
    color: #666;
    padding: 10px;
}

/* Style for empty columns */
.empty-column {
    padding: 10px;
}

/* General table row styling */
.ant-table-row {
    transition: background-color 0.3s;
}

.ant-table-row:hover {
    background-color: #f1f1f1;
}
/* Container for item dates */
.item-dates {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}

/* Style for individual date fields */
.item-date {
    margin: 0;
    font-size: 14px;
    color: #666;
}
.item-status {
    padding: 0px 8px;
    border-radius: 12px;
    color: #fff;
    font-size: 13px;
    display: flex;
    font-weight: bold;
    align-items: center;
    border: 0px solid;
    color: #fff;
}

.item-status::before {
    content: '•';
    margin-right: 5px;
    font-size: 16px;
}

/* Specific styles for each status with border and background color */
.item-status.incoming {
    border-color: none;
    background-color: rgba(255, 77, 79, 0.1);
    color: #ff4d4f;
}

.item-status.assigned {
    border-color: #ffa940;
    background-color: rgba(255, 169, 64, 0.1);
    color: #ffa940;
}

.item-status.planned {
    border-color: #52c41a;
    background-color: rgba(82, 196, 26, 0.1);
    color: #52c41a;
}

.item-status.waiting {
    border-color: #f56a00;
    background-color: rgba(245, 106, 0, 0.1);
    color: #f56a00;
}

.item-status.solved {
    border-color: #87d068;
    background-color: rgba(135, 208, 104, 0.1);
    color: #87d068;
}

.item-status.closed {
    border-color: #8c8c8c;
    background-color: rgba(140, 140, 140, 0.1);
    color: #8c8c8c;
}

.item-status.accepted {
    border-color: #2f54eb;
    background-color: rgba(47, 84, 235, 0.1);
    color: #2f54eb;
}

.item-status.observed {
    border-color: #13c2c2;
    background-color: rgba(19, 194, 194, 0.1);
    color: #13c2c2;
}

.item-status.evaluation {
    border-color: #eb2f96;
    background-color: rgba(235, 47, 150, 0.1);
    color: #eb2f96;
}

.item-status.approval {
    border-color: #722ed1;
    background-color: rgba(114, 46, 209, 0.1);
    color: #722ed1;
}

.item-status.test {
    border-color: #faad14;
    background-color: rgba(250, 173, 20, 0.1);
    color: #faad14;
}

.item-status.qualification {
    border-color: #13c2c2;
    background-color: rgba(19, 194, 194, 0.1);
    color: #13c2c2;
}

.item-requested_for {
    display: flex;
    flex-direction: column;
}

.user {
    display: flex;
    align-items: center;
    padding: 8px 0;
}

.user-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
}

.user-name {
    font-size: 16px;
    color: #333;
}
.requested-for {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0px;
}

.requested-for .ant-avatar {
    margin-right: 10px;
}

.requested-for span {
    font-size: 14px;
    color: #595959;
}
.requested_for_name{
    font-size: 18px;
    font-weight: bold;
}
.ticket-list {
    width: 100%;
    border-collapse: collapse;
  }
  
  .header {
    padding: 8px;
    border-bottom: 2px solid #f0f0f0;
    font-weight: bold;
  }
  
  .ticket-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
  }
  
  .ticket-type {
    display: flex;
    align-items: center;
    padding: 0px 15px 0px 0px;
    border-radius: 12px;
    font-size: 11px;
    display: flex;
    /* font-weight: bold; */
    align-items: center;
    border: 0px solid;
    color:"#212528";
    background-color: #e0ebf7; /* Light background color */
  }
  
  .ticket-icon {
    margin-right: 8px;
    background-color: #e6f7ff;
    color: #1890ff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }
  
  .ticket-category {
    font-size: 13px;
    color: #445160;
  }
::-webkit-scrollbar {
    width: 0;
    display: none; /* Optional: Hide the scrollbar completely */
}
body {
    scrollbar-width: none;
}
.item-container {
    /* display: flex; */
    align-items: center; /* Aligner verticalement au centre */
    padding: 10px; /* Ajouter du padding pour un meilleur espacement */
    /* border: 1px solid #ccc; */
    border-radius: 5px; /* Arrondir les coins */
    background-color: #f9f9f9; /* Ajouter une couleur de fond */
}

.item-info {
    flex: 1; /* Prendre tout l'espace disponible */
}

.notification-icons {
    display: flex; /* Utiliser Flexbox pour les icônes */
    align-items: center; /* Aligner verticalement au centre */
    margin-left: 10px;
}

.notification-icons i {
    color: #ff0000; /* Couleur des icônes */
    margin-right: 5px; /* Espacement entre les icônes */
    font-size: 1.2em; /* Taille des icônes */
}
.mr-2{
    margin-right: 10px !important;
}
.ant-picker-disabled{
    background-color: rgb(233 236 239) !important;
    color: "#000" !important;
}
:where(.css-dev-only-do-not-override-42nv3w).ant-picker .ant-picker-input >input[disabled] {
    background: transparent;
    color: rgb(0, 0, 0);
    cursor: not-allowed;
}
.ant-menu-item-group-title {
    color: #6c757d !important;
}
.validation-status {
    color: green;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

/* Style for department column */
.department-column {
    font-size: 14px;
    color: #333;
    padding: 10px;
}

/* Style for date column */
.date-column {
    font-size: 14px;
    color: #666;
    padding: 10px;
}

/* Style for empty columns */
.empty-column {
    padding: 10px;
}

/* General table row styling */
.ant-table-row {
    transition: background-color 0.3s;
}

.ant-table-row:hover {
    background-color: #f1f1f1;
}
/* Container for item dates */
.item-dates {
    margin-top: 10px;
    font-size: 14px;
    color: #666;
}

/* Style for individual date fields */
.item-date {
    margin: 0;
    font-size: 14px;
    color: #666;
}
.validation-status {
    padding: 0px 8px;
    border-radius: 12px;
    color: #fff;
    font-size: 13px;
    float: right;
    font-weight: bold;
    align-items: center;
    border: 0px solid;
    background-color: rgba(0, 0, 0, 0.1); /* Light background color */
    color: #fff;
}

.validation-status::before {
    content: '•';
    margin-right: 5px;
    font-size: 16px;
}

/* Specific styles for each status with border and background color */
.validation-status.incoming {
    border-color: none;
    background-color: rgba(255, 77, 79, 0.1);
    color: #ff4d4f;
}

.validation-status.assigned {
    border-color: #ffa940;
    background-color: rgba(255, 169, 64, 0.1);
    color: #ffa940;
}

.validation-status.planned {
    border-color: #52c41a;
    background-color: rgba(82, 196, 26, 0.1);
    color: #52c41a;
}

.validation-status.waiting {
    border-color: #f56a00;
    background-color: rgba(245, 106, 0, 0.1);
    color: #f56a00;
}

.validation-status.solved {
    border-color: #87d068;
    background-color: rgba(135, 208, 104, 0.1);
    color: #87d068;
}

.validation-status.closed {
    border-color: #8c8c8c;
    background-color: rgba(140, 140, 140, 0.1);
    color: #8c8c8c;
}

.validation-status.accepted {
    border-color: #2f54eb;
    background-color: rgba(47, 84, 235, 0.1);
    color: #2f54eb;
}

.validation-status.observed {
    border-color: #13c2c2;
    background-color: rgba(19, 194, 194, 0.1);
    color: #13c2c2;
}

.validation-status.evaluation {
    border-color: #eb2f96;
    background-color: rgba(235, 47, 150, 0.1);
    color: #eb2f96;
}

.validation-status.approval {
    border-color: #722ed1;
    background-color: rgba(114, 46, 209, 0.1);
    color: #722ed1;
}

.validation-status.test {
    border-color: #faad14;
    background-color: rgba(250, 173, 20, 0.1);
    color: #faad14;
}

.validation-status.qualification {
    border-color: #13c2c2;
    background-color: rgba(19, 194, 194, 0.1);
    color: #13c2c2;
}
.fg-secondary{
    background-color: #4a5d72 !important;
    border-color: #4a5d72 !important;
    color: #ffcf5c;
    font-size: 14px;
    font-weight: bold;
}
.fg-secondary:hover{
    background-color: transparent !important;
    border-color: #30373f !important;
    color: #30373f;
    font-weight: bold;
}

.fg-secondary:active{
    background-color: transparent !important;
    border-color: #30373f !important;
    color: #30373f;
    font-weight: bold;
}
.list-group-item.active{
    z-index: 2;
    color: #ffffff;
    background-color: #003a70;
    border-color: #003a70;
}
.item-dot::before {
    content: '•';
    margin-right: 5px;
    font-size: 16px;
}

.item-dot{
    background-color: #758CC0;
    border-color: #758CC0;
    color: #fff;
}
.item-dot.active {
    background-color: #005795 !important;
    border-color: #005795 !important;
}

.base-icon {
    font-size: 32px; /* Base icon size */
    color: #28a745; /* Excel green color */
}

.overlay-icon {
    position: absolute;
    font-size: 16px; /* Smaller overlay icon */
    color: #ffffff; /* White color for contrast */
    top: 50%; /* Position it in the middle */
    left: 50%;
    transform: translate(-50%, -50%); /* Center it */
}

.icon-container:hover .base-icon {
    color: #1e7e34; /* Darker green on hover */
}

.icon-container:hover .overlay-icon {
    color: #cccccc; /* Lighter color on hover */
}

.password-reset-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .password-reset-card {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .password-reset-card .form-control {
    margin-bottom: 1rem;
  }
  
  .password-reset-card .btn {
    font-size: 1.1rem;
    padding: 0.75rem;
  }
  
  .password-reset-card .card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .password-reset-card .card-text {
    font-size: 1rem;
  }

  .login-container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f8f9fa;
 }
 
 .login-row {
    width: 100%;
    height: 100%;
    margin: 0;
 }
 
 .login-left {
    /* background: url('./assets/images/your_image.jpg') no-repeat center center; */
    background-size: cover;
 }
 
 .login-right {
    display: flex;
    align-items: center;
    justify-content: center;
 }
 
 .login-card {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: none;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
 }
 
 .login-card .form-control {
    margin-bottom: 1rem;
 }
 
 .login-card .login-button {
    font-size: 1.1rem;
    padding: 0.75rem;
    box-shadow: 0 2px 2px rgba(0, 0, 60, .08);
    height: 52px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
 }
 
 .login-card .card-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
 }
 
 .login-card .alert {
    margin-bottom: 1rem;
 }
 
 .text-muted {
    color: #6c757d !important;
 }
 .item-container {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item-group {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.item-agent {
    font-size: 14px;
    color: #666;
}
.date-widget-container {
    /* padding: 10px; */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
    width: 100%;
  }
  
  .date-widget-item {
    margin-bottom: 10px;
    text-align: left;
  }
  
  .date-widget-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #777;
    margin-bottom: 5px;
  }
  
  .date-widget-icon {
    font-size: 12px;
    color: #003a70;
    margin-right: 8px;
  }
  
  .date-widget-value {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }

.reporting-widget {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    width: 100%; /* Ensure it takes up the full width of the container */
    box-sizing: border-box;
    min-height: 130px;
    background-color: #f9f9f9;
}

.icon-container {
    font-size: 2.5em; /* Increase the icon size */
    color: #007bff;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.category-text, .service-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.category-text {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
}

.service-text {
    font-size: 0.9em;
    color: #666;
}

.button-container-wrapper {
    overflow-x: auto;
    white-space: nowrap;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.button-container-wrapper::-webkit-scrollbar {
    display: none;  /* Chrome, Safari, and Opera */
}

.button-container {
    display: inline-flex;
}
.text-container-next{
    flex-grow: 1;
}
.reporting-widget-next{
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    transition: box-shadow 0.3s ease;
}
.level-tag {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #3b5998;
    padding: 5px 10px;
    border-radius: 4px;
}
.level-tag-niveau1 {
    background-color: #3b5998; /* Replace with the desired color for Niveau 1 */
}

.level-tag-niveau2 {
    background-color: #fd7e14; /* Replace with the desired color for Niveau 2 */
}

.level-tag-niveau3 {
    background-color: #6610f2; /* Replace with the desired color for Niveau 3 */
}
.ant-notification {
    z-index: 9999999;
}
.login-container {
    background-image: url('assets/bglogin.jpg');
    background-size: cover;
    background-position: center;
    height: 100vh; /* Assure que le conteneur occupe toute la hauteur de la fenêtre */
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .login-row {
    width: 100%;
 }
 
 .login-card {
    opacity: 0.9; /* Optionnel : pour améliorer la lisibilité sur l'image */
 }
 
 .login-button {
    margin-top: 20px;
 }
 